import React from 'react';

import { Box, PicnicCss, styled, Text, Tooltip } from '@attentive/picnic';

const TooltipHeader = styled(Box, {
  fontSize: '$fontSize2',
  textDecoration: 'underline dashed',
  '&:hover': {
    textDecoration: 'underline solid',
  },
});

export const TooltipHeaderContent = ({
  name,
  description,
  css,
  ...props
}: {
  name: string;
  description: string | undefined | null;
  css?: PicnicCss;
}) => {
  return description ? (
    <Tooltip>
      <Tooltip.Trigger>
        <TooltipHeader css={css} {...props}>
          {name}
        </TooltipHeader>
      </Tooltip.Trigger>
      <Tooltip.Content side="top">{description}</Tooltip.Content>
    </Tooltip>
  ) : (
    <Text css={{ fontSize: '$fontSize2', ...css }} {...props}>
      {name}
    </Text>
  );
};
